<template>
  <div>
    <div
      v-if="
        (Array.isArray(moduleData[resourceListKey]) && moduleData[resourceListKey].length > 0) ||
        (Array.isArray(moduleData.fileList) && moduleData.fileList.length > 0)
      "
      class="module-content"
    >
      <ResourceContent
        ref="resourceContentRef"
        :resource-data="resourceData"
      />
      <div
        v-if="
          (!Array.isArray(moduleData[resourceListKey]) || moduleData[resourceListKey].length === 0) &&
          (Array.isArray(moduleData.fileList) && moduleData.fileList.length > 0)
        "
        class="module-content-empty"
        style="height: 320px;"
      > 
        仅有附件
      </div>
      <OperBar
      ref="operBarRef"
      class="oper-bar-wrap"
      :module-title="moduleData.title"
      :current-resource="currentResource"
      :current-resource-processed-data="resourceData"
      :resource-list="moduleData[resourceListKey]"
      :file-list="moduleData.fileList"
      @current-resource-change="handleCurrentResourceChange"
      @pic-pre="handlePicPre"
      @pic-next="handlePicNext"
      v-bind="$attrs"
    />
      
    </div>
    <div v-else class="module-content-empty">该模块下无内容</div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, watch, computed } from 'vue';

  import ResourceContent from './ResourceContent.vue';
  import OperBar from './OperBar.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { getTeachingDetail } from '@/api/teaching.js';
  import { getExerciseDetails } from '@/api/exercises.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  const props = defineProps({
    moduleData: {
      type: Object,
      default: () => {}
    },
    chapterSectionType: {
      type: String,
      default: ''
    }
  });
  const emit = defineEmits([
    'resource-title-change',
    'brief-info-change',
    'label-list-change'
  ]);

  
  const resourceListKey = computed(() => {
    
    switch (props.chapterSectionType) {
      case 'chapter':
        return 'chapter_type_source_list';
      case 'section':
        return 'type_source_list';
      case '':
        return 'type_source_list';
    }
  });
  function handleResourceTitleChange(title) {
    emit('resource-title-change', title);
  }
  function handleBriefInfoChange(data) {
    emit('brief-info-change', data);
  }
  function handleLabelListChange(labelIds, labelList) {
    const tempList = labelList.filter(item => labelIds.includes(item.id)).map(item => item.title);
    emit('label-list-change', tempList);
  }

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const resourceContentRef = ref(null);
  const operBarRef = ref(null);

  const currentResource = ref({});
  const resourceData = ref({});
  /*
  resourceData = {
    resourceType: xxx,
    teachingType: xxx,
    contents: [
      { name: xx, ext: '.xx', url: 'xx', videoId: 'xx' },

      { title: '', options: ['A.XXX', 'B.XXX'], answer: 'A B', score: 'xx.xx', type: x, analysis: xx }
    ]
  }
  */
  const indexResourceStore = ref({});
  const clearStore = () => {
    indexResourceStore.value = {};
  };
  const storeResource = (key, value) => {
    indexResourceStore.value[key] = value;
  };
  const setResourceData = ({ resourceIndex, resourceType, teachingType, list }) => {
    const tempResource = {
      resourceType,
      teachingType
    };
    if (Array.isArray(list)) {
      switch (resourceType) {
        case resourceEnum.TEACHING_RESOURCE_TYPE:
          tempResource.contents = list.map(({
            file_name: name,
            extension: ext,
            file_url: url,
            video_id: videoId
          }) => ({
            name,
            ext,
            url,
            videoId
          }));
          break;
        case resourceEnum.EXERCISES_RESOURCE_TYPE:
          tempResource.contents = list.map(({
            title,
            ques_select: options,
            ques_answer: answer,
            score,
            type,
            ques_analys: analysis,
            upload_list: fileList
          }) => ({
            title,
            options,
            answer,
            score,
            type,
            analysis,
            fileList
          }));
          break;
      }
    }
    resourceData.value = tempResource;
    storeResource(resourceIndex, tempResource);
  };
  const getResourceData = (data) => {
    const { data_type, source_id, title } = data;
    const resourceIndex = props.moduleData[resourceListKey.value].indexOf(data);
    switch (Number(data_type)) {
      case resourceEnum.TEACHING_RESOURCE_TYPE:
        loading.value = true;
        getTeachingDetail({
          'Teaching[id]': source_id[0]
        }).then(res => {
          if (res.code === SUCCESS) {
            setResourceData({
              resourceIndex,
              resourceType: resourceEnum.TEACHING_RESOURCE_TYPE,
              teachingType: Number(res.data.type),
              list: res.data.source
            });
            handleResourceTitleChange(res.data.title);
            if (Array.isArray(res.data.source) && res.data.source && res.data.source[0]) {
              handleBriefInfoChange({
                creator: res.data.source[0].admin_username,
                updateTime: res.data.source[0].update_time * 1000
              });
            }
            handleLabelListChange(res.data.label_ids, res.data.label_list);
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
        break;
      case resourceEnum.EXERCISES_RESOURCE_TYPE:
        loading.value = true;
        getExerciseDetails({
          'Material[ids]': source_id
        }).then(res => {
          if (res.code === SUCCESS) {
            setResourceData({
              resourceIndex,
              resourceType: resourceEnum.EXERCISES_RESOURCE_TYPE,
              list: res.data
            });
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
        break;
      case resourceEnum.PAPER_RESOURCE_TYPE:
        setResourceData({
          resourceIndex,
          resourceType: resourceEnum.EXERCISES_RESOURCE_TYPE,
          list: source_id
        });
    }
  };
  const handleCurrentResourceChange = data => {
    currentResource.value = data;
    const resourceIndex = props.moduleData[resourceListKey.value].indexOf(data);
    const storedData = indexResourceStore.value[resourceIndex];
    if (!storedData) {
      getResourceData(data);
    } else {
      resourceData.value = storedData;
    }

    resourceContentRef.value && resourceContentRef.value.resetData();
  };

  watch(() => props.moduleData, value => {
    if (!currentResource.value.title) { // 设置默认展示的资源
      const resourceList = value[resourceListKey.value];
      if (Array.isArray(resourceList) && resourceList.length) {
        handleCurrentResourceChange(resourceList[0]);
      }
    }
  }, { immediate: true });

  const handlePicPre = () => {
    resourceContentRef.value.prePic();
  };
  const handlePicNext = () => {
    resourceContentRef.value.nextPic();
  };
</script>

<style lang="less" scoped>
  .module-empty {
    min-height: 500px;
    padding: 0 20px;
    color: #999;
    font-size: 16px;
  }

  .module-content {
    position: relative;
    padding-bottom: 79px;

    &:hover .oper-bar-wrap {
      display: block;
    }
  }
  .oper-bar-wrap {
    position: fixed;
    margin-right: 15px;
    width: 76.8vw;
    right: 0;
    bottom: 15px;
    z-index: 1;
    right: 0;

    &.fixed {
      position: fixed;
      top: 18%;
      right: 35px;
      width: 56px;
      padding: 10px 15px 15px;
      transform: translate(0,0);
      border-radius: 6px;
      background-color: rgba(255,255,255,.8);
    }
  }

  .module-content-empty {
    padding: 0 16px 12px;
    color: #999;
    font-size: 16px;
  }
</style>